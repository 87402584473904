/* 
========================================= 
========================================= 

   Bootstrapious Boilerplate Template

========================================= 
========================================= */
/*
*
* ======================================================================
* GENERAL
* ======================================================================
*
*/
body {
  overflow-x: hidden;
  background-color: #f0f0f0;
}

a, button {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: none;
}

a i.fa, button i.fa {
  margin: 0 5px;
}

h1 {
  font-weight: 700;
}

.text-uppercase {
  letter-spacing: .2em;
}

.btn-primary {
  color: #fff !important;
}

/*.btn + .btn {*/
/*  margin-left: 5px;*/
/*}*/

.disabled {
  cursor: not-allowed;
}

.clickable {
  cursor: pointer;
}

.menu-large {
  position: static !important;
}

.menu-large .megamenu {
  padding: 20px;
  width: 100%;
  max-width: 1140px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -1px;
}

.menu-large a.nav-link {
  padding: 7px !important;
  padding-left: 0 !important;
}

/*
*
* ======================================================================
* TOP BAR
* ======================================================================
*
*/
/*#top {*/
/*  background: #555;*/
/*  padding: 2px 0;*/
/*}*/

/*#top .offer {*/
/*  color: #fff;*/
/*}*/

/*#top .offer .btn {*/
/*  text-transform: uppercase;*/
/*  letter-spacing: 0.1em;*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*  #top {*/
/*    font-size: 0.738rem;*/
/*    text-align: center;*/
/*  }*/
/*}*/

/*#top a {*/
/*  color: #fff;*/
/*}*/

/*#top ul.menu {*/
/*  padding-top: 5px;*/
/*  margin: 0;*/
/*  font-size: 0.738rem;*/
/*}*/

/*#top ul.menu > li {*/
/*  margin-right: 0;*/
/*}*/

/*#top ul.menu > li a {*/
/*  color: #eee;*/
/*}*/

/*#top ul.menu > li + li:before {*/
/*  content: "|\00a0";*/
/*  padding: 0 5px;*/
/*  color: #f8f9fa;*/
/*}*/

/*#top ul.menu > .active {*/
/*  color: #999;*/
/*}*/

/*#top #login-modal .modal-header {*/
/*  background: #4fbfa8;*/
/*}*/

/*#top #login-modal .modal-header h5 {*/
/*  color: #fff;*/
/*}*/

/*#top #login-modal a {*/
/*  color: #4fbfa8;*/
/*}*/

/*#top #login-modal p {*/
/*  font-weight: 300;*/
/*  margin-bottom: 20px;*/
/*}*/

/*
*
* ======================================================================
* NAVBAR
* ======================================================================
*
*/
/*.navbar {*/
/*  border-bottom: 1px solid #e6e6e6;*/
/*  background-color: #fff;*/
/*}*/

/*.navbar .navbar-nav > .nav-item > .nav-link {*/
/*  text-transform: uppercase;*/
/*  letter-spacing: 0.1em;*/
/*  font-weight: 700;*/
/*  padding: 30px 15px !important;*/
/*  color: #555;*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*  .navbar .navbar-nav > .nav-item > .nav-link {*/
/*    padding: 10px 15px !important;*/
/*  }*/
/*}*/

/*.navbar .navbar-nav > .nav-item > .nav-link.active, .navbar .navbar-nav > .nav-item > .nav-link:hover, .navbar .navbar-nav > .nav-item > .nav-link:focus {*/
/*  background: #4fbfa8;*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*  .navbar .dropdown-menu {*/
/*    position: relative !important;*/
/*    border: none;*/
/*  }*/
/*}*/

/*@media (min-width: 992px) {*/
/*  .navbar .dropdown:hover .dropdown-menu {*/
/*    display: block;*/
/*  }*/
/*}*/

/*.navbar .megamenu {*/
/*  padding: 30px !important;*/
/*  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);*/
/*  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*  .navbar .megamenu {*/
/*    padding: 15px !important;*/
/*    -webkit-box-shadow: none;*/
/*    box-shadow: none;*/
/*  }*/
/*}*/

/*.navbar .menu-large h5 {*/
/*  font-size: 1.125rem;*/
/*  font-weight: 400;*/
/*  text-transform: uppercase;*/
/*  letter-spacing: 0.1em;*/
/*  padding-bottom: 10px;*/
/*  border-bottom: dotted 1px #555;*/
/*}*/

/*@media (max-width: 767.98px) {*/
/*  .navbar .menu-large h5 {*/
/*    font-size: 0.9rem;*/
/*  }*/
/*}*/

/*.navbar .menu-large ul {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/

/*.navbar .menu-large ul .nav-item {*/
/*  list-style-type: none;*/
/*  border-bottom: solid 1px #eee;*/
/*  text-transform: uppercase;*/
/*  letter-spacing: 0.1em;*/
/*  padding: 4px 0;*/
/*}*/

/*.navbar .menu-large ul .nav-item a {*/
/*  color: #999;*/
/*  font-size: 0.738rem;*/
/*  display: block;*/
/*  padding: 0 !important;*/
/*}*/

/*.navbar .menu-large ul .nav-item a:hover {*/
/*  color: #4fbfa8 !important;*/
/*  text-decoration: none;*/
/*}*/

/*.navbar .menu-large .banner {*/
/*  margin-bottom: 10px;*/
/*}*/

/*.navbar #basket-overview {*/
/*  padding: 0;*/
/*}*/

/*.navbar .navbar-btn {*/
/*  color: #fff;*/
/*  margin-right: 10px;*/
/*}*/

/*#search {*/
/*  background: #fff;*/
/*}*/

/*#search .container {*/
/*  text-align: right;*/
/*}*/

/*#search form {*/
/*  max-width: 500px;*/
/*  padding: 20px 0;*/
/*}*/

/*#search .navbar-form {*/
/*  float: right;*/
/*  width: 500px;*/
/*}*/

/*.breadcrumb {*/
/*  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);*/
/*  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);*/
/*  background: #fff;*/
/*  border-radius: 0;*/
/*  padding: 0.5rem 1rem;*/
/*}*/

/*
*
* ======================================================================
* HOT
* ======================================================================
*
*/
#hot h2 {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 2.25rem;
  color: #4fbfa8;
  font-weight: 100;
  text-align: center;
}

#hot .product-slider {
  clear: both;
  margin-bottom: 20px;
}

#hot .product-slider .item {
  margin: 0 25px;
}

#hot .product-slider .product {
  margin-bottom: 0;
}

#hot .product-slider .owl-dots {
  position: relative;
  margin-bottom: 30px;
}

/*
*
* ======================================================================
* ADVANTAGES
* ======================================================================
*
*/
/*#advantages {*/
/*  text-align: center;*/
/*}*/

/*#advantages .box {*/
/*  position: relative;*/
/*}*/

/*#advantages .box .icon {*/
/*  position: absolute;*/
/*  font-size: 120px;*/
/*  width: 100%;*/
/*  text-align: center;*/
/*  top: 50%;*/
/*  left: 50%; !* Adjusted *!*/
/*  transform: translate(-50%, -50%); !* Added *!*/
/*  color: var(--bs-gray-200);*/
/*  transition: all 0.3s;*/
/*  z-index: 1;*/
/*}*/

/*!* Adjust icon size for smaller screens if needed *!*/
/*@media (max-width: 576px) {*/
/*  #advantages .box .icon {*/
/*    font-size: 80px;*/
/*  }*/
/*}*/


/*!* Adjust icon size for smaller screens if needed *!*/
/*@media (max-width: 576px) {*/
/*  #advantages .box .icon {*/
/*    font-size: 80px; !* Adjust as needed *!*/
/*  }*/
/*}*/


/*#advantages .box h4 {*/
/*  position: relative;*/
/*  margin: 0 0 10px;*/
/*  font-weight: 300;*/
/*  text-transform: uppercase;*/
/*  letter-spacing: 0.1em;*/
/*  z-index: 2;*/
/*}*/

/*#advantages .box h3 a:hover {*/
/*  text-decoration: none;*/
/*}*/

/*#advantages .box p {*/
/*  position: relative;*/
/*  color: #555;*/
/*  z-index: 2;*/
/*}*/

/*
*
* ======================================================================
* SLIDERS
* ======================================================================
*
*/
#main-slider {
  margin-bottom: 30px;
  border: solid 1px #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.box.slideshow ul li div, #main-slider ul li div {
  width: 100%;
}

.box.slideshow .owl-dots, #main-slider .owl-dots {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

/*
*
* ======================================================================
* FOOTER
* ======================================================================
*
*/
/*#footer {*/
/*  background: #e0e0e0;*/
/*  padding: 100px 0;*/
/*}*/

/*#footer ul {*/
/*  padding-left: 0;*/
/*  list-style: none;*/
/*}*/

/*#footer ul a {*/
/*  color: #999;*/
/*}*/

/*#footer .social {*/
/*  text-align: left;*/
/*}*/

/*#footer .social a {*/
/*  margin: 0 10px 0 0;*/
/*  color: #fff;*/
/*  display: inline-block;*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  border-radius: 15px;*/
/*  line-height: 26px;*/
/*  font-size: 15px;*/
/*  text-align: center;*/
/*  -webkit-transition: all 0.3s;*/
/*  transition: all 0.3s;*/
/*  vertical-align: bottom;*/
/*  background-color: #868585;*/
/*}*/

/*#footer .social a i {*/
/*  vertical-align: bottom;*/
/*  line-height: 30px;*/
/*}*/

/*#footer .social a.facebook:hover {*/
/*  background-color: #4460ae;*/
/*}*/

/*#footer .social a.yelp:hover {*/
/*  background-color: #c21f25;*/
/*}*/

/*#footer .social a.twitter:hover {*/
/*  background-color: #3cf;*/
/*}*/

/*#footer .social a.instagram:hover {*/
/*  background-color: #cd4378;*/
/*}*/

/*#footer .social a.email:hover {*/
/*  background-color: #4a7f45;*/
/*}*/

/*
*
* ======================================================================
* COPYRIGHTS
* ======================================================================
*
*/
#copyright {
  background: #333;
  color: #ccc;
  padding: 20px 0;
  font-size: 0.738rem;
}

#copyright p {
  margin: 0;
}

/*
*
* ======================================================================
* BOXES
* ======================================================================
*
*/
.box {
  background: #fff;
  margin: 0 0 30px;
  border: solid 1px #e6e6e6;
  padding: 30px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.box .box-header {
  clear: both;
  background: #f7f7f7;
  margin: -20px -20px 20px;
  padding: 20px;
  border-bottom: solid 1px #eee;
}

.box .box-footer {
  clear: both;
  background: #f7f7f7;
  margin: 30px -20px -20px;
  padding: 20px;
  border-top: solid 1px #eee;
}

@media (max-width: 767.98px) {
  .box .box-footer .btn {
    margin-bottom: 20px;
  }
}

.box.slideshow {
  padding: 20px 0 0 0;
  text-align: center;
}

.box.slideshow h3 {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
}

/*
*
* ======================================================================
* RIBBONS
* ======================================================================
*
*/
.ribbon {
  position: absolute;
  top: 50px;
  padding-left: 41px;
  z-index: 20;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.ribbon .ribbon-background {
  position: absolute;
  top: 0;
  right: 0;
}

.ribbon .theribbon {
  position: relative;
  width: 80px;
  padding: 6px 0px 5px 10px;
  margin: 10px 10px 10px -71px;
  color: #fff;
  background-color: #4fbfa8;
  text-shadow: 0px 1px 2px #bbb;
}

.ribbon .theribbon:before, .ribbon .theribbon:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}

.ribbon .theribbon:after {
  left: 0px;
  top: 100%;
  border-width: 5px 10px;
  border-style: solid;
  border-color: #2d7b6b #2d7b6b transparent transparent;
}

.ribbon.sale {
  top: 0px;
}

.ribbon.new {
  top: 0px;
}

.ribbon.new .theribbon {
  background-color: #17a2b8;
  text-shadow: 0px 1px 2px #bbb;
}

.ribbon.new .theribbon:after {
  border-color: #0c525d #0c525d transparent transparent;
}

.ribbon.gift {
  top: 100px;
}

.ribbon.gift .theribbon {
  background-color: #28a745;
  text-shadow: 0px 1px 2px #bbb;
}

.ribbon.gift .theribbon:after {
  border-color: #145523 #145523 transparent transparent;
}

/*
*
* ======================================================================
* CONTENT
* ======================================================================
*
*/
#content .card.sidebar-menu {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

#content .card.sidebar-menu .card-header .btn.btn-danger {
  color: #fff;
}

#content .card.sidebar-menu .badge-light {
  color: #4fbfa8;
}

#content .card.sidebar-menu .card {
  padding: 15px;
  background: #fff;
}

#content .card.sidebar-menu .card span.colour {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: solid 1px #555;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 5px;
}

#content .card.sidebar-menu .card span.colour.white {
  background: #fff;
}

#content .card.sidebar-menu .card span.colour.red {
  background: red;
}

#content .card.sidebar-menu .card span.colour.green {
  background: green;
}

#content .card.sidebar-menu .card span.colour.blue {
  background: blue;
}

#content .card.sidebar-menu .card span.colour.yellow {
  background: yellow;
}

#content .card.sidebar-menu .card label {
  color: #555;
  font-size: 0.738rem;
}

#content .card.sidebar-menu .card label::hover {
  color: #555;
}

#content .card.sidebar-menu h3 {
  padding: 5px 0;
  margin: 0;
}

#content .card.sidebar-menu ul.nav.category-menu {
  margin-bottom: 20px;
}

#content .card.sidebar-menu ul.nav.category-menu li a {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#content .card.sidebar-menu ul.nav.category-menu li a:not(.active):hover {
  background: #eeeeee;
}

#content .card.sidebar-menu ul.nav ul {
  list-style: none;
  padding-left: 0;
}

#content .card.sidebar-menu ul.nav ul li {
  display: block;
}

#content .card.sidebar-menu ul.nav ul li a {
  position: relative;
  font-weight: normal;
  text-transform: none !important;
  display: block;
  padding: 10px 15px;
  padding-left: 30px;
  font-size: 0.738rem;
  color: #555;
}

#content .card.sidebar-menu ul.nav ul li a:hover, #content .card.sidebar-menu ul.nav ul li a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}

#content .info-bar {
  line-height: 32px;
  vertical-align: middle;
}

#content .info-bar .products-number strong {
  margin-right: 10px;
}

#content .info-bar .products-number span {
  margin-left: 10px;
}

#content .info-bar .products-sort-by select {
  margin-left: 10px;
}

#content .product {
  background: #fff;
  border: solid 1px #e6e6e6;
  margin-bottom: 30px;
  /* entire container, keeps perspective */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /*  UPDATED! front pane, placed above back */
  /* back, initially hidden pane */
}

#content .product .flip-container {
  cursor: pointer;
  -webkit-perspective: 1000;
  perspective: 1000;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

#content .product .flip-container, #content .product .front, #content .product .back {
  width: 100%;
}

#content .product .flipper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

#content .product .front, #content .product .back {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
}

#content .product .front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

#content .product .back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

#content .product:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  z-index: 2;
}

#content .product:hover .front {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 1;
}

#content .product .invisible {
  visibility: hidden;
}

#content .product .text {
  padding: 10px 10px 0;
}

#content .product .text h3 {
  font-size: 1.125rem;
  font-weight: 700;
  height: 42px;
  text-align: center;
  overflow: hidden;
}

#content .product .text h3 a {
  color: #555;
}

#content .product .text p.price {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 300;
}

#content .product .text p.price del {
  color: #999;
}

#content .product .text .buttons {
  clear: both;
  text-align: center;
}

#content .product .text .buttons .btn {
  margin-bottom: 10px;
}

#content .banner {
  margin-bottom: 30px;
}

#content .pages {
  text-align: center;
}

#content .pages .loadMore {
  text-align: center;
}

#content .pages .pagination {
  text-align: center;
}

#content #mainImage {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

#content #productMain {
  margin-bottom: 30px;
}

#content #productMain .goToDescription {
  margin-top: 20px;
  font-size: 0.738rem;
  text-align: center;
}

#content #productMain .goToDescription a {
  color: #999;
  text-decoration: underline;
}

#content #productMain .price {
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  margin-top: 40px;
}

#content #productMain .buttons {
  margin-bottom: 0;
  text-align: center;
}

#content #productMain .buttons .btn {
  margin-bottom: 10px;
}

#content #details .social {
  text-align: left;
}

#content #details .social h4 {
  font-weight: 300;
  margin-bottom: 10px;
}

#content #details .social p {
  line-height: 26px;
}

#content #details .social p a {
  margin: 0 10px 0 0;
  color: #fff;
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  line-height: 26px;
  font-size: 15px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  vertical-align: bottom;
}

#content #details .social p a i {
  vertical-align: bottom;
  line-height: 26px;
}

#content #details .social p a.facebook {
  background-color: #4460ae;
}

#content #details .social p a.gplus {
  background-color: #c21f25;
}

#content #details .social p a.twitter {
  background-color: #3cf;
}

#content #details .social p a.instagram {
  background-color: #cd4378;
}

#content #details .social p a.email {
  background-color: #4a7f45;
}

#content #thumbs a {
  display: block;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  border: solid 2px transparent;
}

#content #thumbs a.active {
  border-color: #4fbfa8;
}

#content #checkout .nav {
  margin-bottom: 20px;
  border-bottom: solid 1px #4fbfa8;
}

#content #checkout .nav a {
  display: block;
  height: 100%;
}

#content #checkout .nav a i {
  display: block;
  margin-bottom: 5px;
}

#content #order-summary table {
  margin-top: 20px;
}

#content #order-summary table td {
  color: #999;
}

#content #order-summary table tr.total td, #content #order-summary table tr.total th {
  font-size: 1.125rem;
  color: #555;
  font-weight: 700;
}

#content #checkout .table tbody tr td, #content #basket .table tbody tr td, #content #customer-order .table tbody tr td {
  vertical-align: middle;
}

#content #checkout .table tbody tr td input, #content #basket .table tbody tr td input, #content #customer-order .table tbody tr td input {
  width: 50px;
  text-align: right;
}

#content #checkout .table tbody tr td img, #content #basket .table tbody tr td img, #content #customer-order .table tbody tr td img {
  width: 50px;
}

#content #checkout .table tfoot, #content #basket .table tfoot, #content #customer-order .table tfoot {
  font-size: 1.125rem;
}

#content #text-page h1, #content #text-page h2, #content #text-page h3 {
  font-weight: 700;
}

#content #error-page {
  text-align: center;
}

#content #error-page h4 {
  margin-bottom: 40px;
}

#content #error-page p.buttons {
  margin-top: 40px;
}

#content #map {
  height: 400px;
}

#content #blog-listing .post, #content #blog-homepage .post {
  margin-bottom: 15px;
  background: #fff;
  margin: 0 0 30px;
  border: solid 1px #e6e6e6;
  padding: 20px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

#content #blog-listing .post h2 a, #content #blog-listing .post h4 a, #content #blog-homepage .post h2 a, #content #blog-homepage .post h4 a {
  color: #555;
}

#content #blog-listing .post h2 a:hover, #content #blog-listing .post h4 a:hover, #content #blog-homepage .post h2 a:hover, #content #blog-homepage .post h4 a:hover {
  color: #4fbfa8;
}

#content #blog-listing .post .author-category, #content #blog-homepage .post .author-category {
  color: #999;
  font-weight: 300;
}

#content #blog-listing .post .date-comments a, #content #blog-homepage .post .date-comments a {
  color: #999;
  margin-right: 20px;
}

#content #blog-listing .post .date-comments a:hover, #content #blog-homepage .post .date-comments a:hover {
  color: #4fbfa8;
}

#content #blog-listing .post .intro, #content #blog-homepage .post .intro {
  text-align: left;
}

#content #blog-listing .post .image, #content #blog-homepage .post .image {
  margin-bottom: 10px;
  overflow: hidden;
}

#content #blog-listing .post .image img, #content #blog-homepage .post .image img {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#content #blog-listing .post .read-more, #content #blog-homepage .post .read-more {
  text-align: right;
}

#content #blog-listing .post:hover .image img, #content #blog-homepage .post:hover .image img {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

#content #blog-listing .pager, #content #blog-homepage .pager {
  margin: 20px 0;
}

#content #blog-listing .pager a, #content #blog-homepage .pager a {
  border-radius: 30px !important;
  background: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

#content #blog-listing .pager a:not(.disabled):hover, #content #blog-homepage .pager a:not(.disabled):hover {
  background: #4fbfa8;
}

#content #blog-homepage .post {
  margin-bottom: 30px;
}

#content #blog-homepage .post h2, #content #blog-homepage .post h4, #content #blog-homepage .post .author-category, #content #blog-homepage .post .read-more {
  text-align: center;
}

#content #blog-homepage .post .intro {
  font-weight: 300;
}

#content #blog-homepage .post .read-more {
  margin-top: 20px;
}

#content #blog-post .author-date {
  color: #999;
  font-weight: 300;
}

#content #blog-post #post-content {
  margin-bottom: 20px;
}

#content #blog-post .comment {
  clear: both;
  margin-bottom: 25px;
}

#content #blog-post .comment .posted {
  color: #999;
  font-size: 0.738rem;
}

#content #blog-post .comment .reply {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

#content #blog-post .comment.last {
  margin-bottom: 0;
}

#content #blog-post #comments, #content #blog-post #comment-form {
  clear: both;
}

#content #blog-post #comments h4, #content #blog-post #comment-form h4 {
  margin-bottom: 20px;
}

#content #blog-post #comment-form {
  margin-bottom: 20px;
}

#content #customer-orders table tr th, #content #customer-orders table tr td {
  vertical-align: baseline;
}

#content #customer-order .table tfoot th {
  font-size: 1.125rem;
  font-weight: 300;
}

#content #customer-order .addresses {
  text-align: right;
}

#content #customer-order .addresses p {
  font-size: 1.125rem;
  font-weight: 300;
}

.owl-carousel .owl-dots .owl-dot {
  outline: none;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #4fbfa8;
}

.owl-thumbs button {
  display: inline-block;
  background: none;
  border: none;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0;
  outline: none;
  cursor: pointer;
  opacity: .6;
}

.owl-thumbs button.active {
  opacity: 1;
}

.owl-thumbs button img {
  max-width: 88px;
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 120px;
  left: 0px;
  border-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 160px;
  left: 0;
  background: #fff;
  border: solid 1px #ced4da;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* =========================================

      THEMING OF THE BOOTSTRAP COMPONENTS

   =========================================

    1 - NAVBAR
    2 - BUTTONS
    3 - TYPE
    4 - PAGINATION
    5 - UTILITIES
    6 - FORMS
    7 - CODE
    8 - NAV
    9 - CARD
    10 - DROPDOWNS

*/
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33125rem;
  padding-bottom: 0.33125rem;
  margin-right: 1rem;
  font-size: 1.125rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:focus, .btn.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

.btn-link {
  font-weight: 400;
  color: #4fbfa8;
}

.btn-link:hover {
  color: #348e7b;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-primary {
  color: #212529;
  background-color: #4fbfa8;
  border-color: #4fbfa8;
}

.btn-primary:hover {
  color: #fff;
  background-color: #3eaa94;
  border-color: #3aa18c;
}

.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #212529;
  background-color: #4fbfa8;
  border-color: #4fbfa8;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3aa18c;
  border-color: #379783;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #4fbfa8;
  background-color: transparent;
  background-image: none;
  border-color: #4fbfa8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4fbfa8;
  border-color: #4fbfa8;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4fbfa8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #4fbfa8;
  border-color: #4fbfa8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.738rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/*
 * 3. TYPE
 */
body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f0f0f0;
}

a {
  color: #4fbfa8;
  text-decoration: none;
}

a:hover, a:focus {
  color: #348e7b;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.53rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 0.9rem;
}

h6,
.h6 {
  font-size: 0.765rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 0.9rem;
  border-left: 5px solid #4fbfa8;
}

.blockquote-footer {
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #4fbfa8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #3aa18c !important;
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #4fbfa8;
  border-color: #4fbfa8;
}

.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #4fbfa8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover, .page-link:focus {
  color: #348e7b;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.738rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/*
* 5. UTILITIES
*/
.bg-primary {
  background-color: #4fbfa8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3aa18c !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.border-primary {
  border-color: #4fbfa8 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-primary {
  color: #4fbfa8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #3aa18c !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.badge-primary {
  color: #212529;
  background-color: #4fbfa8;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #3aa18c;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

/*
  * 6. FORMS
  */
.form-control {
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ade1d6;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.form-control::-moz-placeholder {
  color: #6c757d;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
}

.form-control::placeholder {
  color: #6c757d;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.1rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.738rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.607rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.6875rem + 2px);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #4fbfa8;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #d2efe9;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4fbfa8;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #4fbfa8;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(79, 191, 168, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(79, 191, 168, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4fbfa8;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(79, 191, 168, 0.5);
}

/*
* 7.CODE
*/
code {
  font-size: 87.5%;
  color: #e83e8c;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f0f0f0;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4fbfa8;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fa;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
}

.card-img-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}

/*
* 10. DROPDOWNS
*/
.dropdown-menu {
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
  font-weight: 400;
  color: #212529;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #4fbfa8;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
}
