/*
*
* ======================================================================
* GENERAL
* ======================================================================
*
*/

.App {
    text-align: center;
}

/*
*
* ======================================================================
* NAVBAR
* ======================================================================
*
*/

.navbar .navbar-nav > .nav-item > .nav-link.active,
.navbar .navbar-nav > .nav-item > .nav-link:hover,
.navbar .navbar-nav > .nav-item > .nav-link:focus {
    background: #4fbfa8;
}

/*
*
* ======================================================================
* ADVANTAGES
* ======================================================================
*
*/
#advantages {
    text-align: center;
}

#advantages .box {
    position: relative;
}

#advantages .box .icon {
    position: absolute;
    font-size: 120px;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%; /* Adjusted */
    transform: translate(-50%, -50%); /* Added */
    color: var(--bs-gray-200);
    transition: all 0.3s;
    z-index: 1;
}

/* Adjust icon size for smaller screens if needed */
@media (max-width: 576px) {
    #advantages .box .icon {
        font-size: 80px;
    }
}


/* Adjust icon size for smaller screens if needed */
@media (max-width: 576px) {
    #advantages .box .icon {
        font-size: 80px; /* Adjust as needed */
    }
}


#advantages .box h4 {
    position: relative;
    margin: 0 0 10px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    z-index: 2;
}

#advantages .box h4 a:hover {
    text-decoration: none;
}

#advantages .box p {
    position: relative;
    color: #555;
    z-index: 2;
}

/*
*
* ======================================================================
* FOOTER
* ======================================================================
*
*/

#footer .social a {
    /* Margin to create space between icons */
    margin: 0 10px 0 0;
    /* Icon color */
    color: #fff;
    /* Display as inline-block */
    display: inline-block;
    /* Width and height of the icon */
    width: 30px;
    height: 30px;
    /* Border radius to create rounded corners */
    border-radius: 15px;
    /* Line height for vertical alignment */
    line-height: 30px;
    /* Font size */
    font-size: 15px;
    /* Text alignment */
    text-align: center;
    /* Transition effect for smooth hover */
    transition: all 0.3s;
    /* Background color */
    background-color: #4fbfa8;;
    /* Vertical alignment */
    vertical-align: bottom;
}

#footer .social a.facebook:hover {
    background-color: #4460ae;
}

#footer .social a.yelp:hover {
    background-color: #c21f25;
}

#footer .social a.twitter:hover {
    background-color: #3cf;
}

#footer .social a.instagram:hover {
    background-color: #cd4378;
}

#footer .social a.email:hover {
    background-color: #4a7f45;
}